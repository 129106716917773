<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <img class="charity" :src="logo" />

      <InputOptionLarge
        v-bind:letter="'a'"
        v-bind:selected="personalDetails.charity_donate === 'amount'"
        v-bind:option="'Gift a fixed sum of money'"
        v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-amount.png'"
        @selected="save('amount')"
      >
        <template v-slot:text>
          <div class="pricing-sub-text">
            Select this option if you are a single person or if you have a
            partner but wish to make a Will only for yourself.
          </div>
        </template>
      </InputOptionLarge>
      <InputOptionLarge
        v-bind:letter="'b'"
        v-bind:selected="personalDetails.charity_donate === 'percentage'"
        v-bind:option="'Gift a percentage of your estate'"
        v-bind:image="'https://mylastwill.s3.amazonaws.com/static/img/button-donate-custom-percent.png'"
        @selected="save('percentage')"
      >
        <template v-slot:text>
          <div class="pricing-sub-text">
            Select this option if you are a single person or if you have a
            partner but wish to make a Will only for yourself.
          </div>
        </template>
      </InputOptionLarge>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionLarge from '@/common/ui/InputOptionLarge'

export default {
  name: 'ProfileCharityOption',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionLarge
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    number: () => '6',
    start() {
      return this.$router.history.current.name === 'WriteMyWillCharityOption'
    },
    forwardTo() {
      if (this.personalDetails.charity_donate === 'amount' && this.start)
        return '/write_my_will/charity_amount'
      else if (this.personalDetails.charity_donate === 'amount')
        return '/profile/charity_amount'
      else if (this.start) return '/write_my_will/charity_percentage'
      return '/profile/charity_percentage'
    },
    backTo() {
      if (this.start) return '/write_my_will/dob'
      return '/profile/dob'
    },
    heading() {
      if (this.charity && this.charity.name)
        return 'How would you like to support ' + this.charity.name
      return 'How would you like to support your charity'
    },
    subHeading() {
      if (this.charity) {
        return (
          this.charity.name +
          ' provides a free Will writing service to supporters. To qualify, your Will must contain a legacy of at least £' +
          this.charity.min_amount +
          ', this is paid from your estate.'
        )
      }
      return ''
    },
    logo() {
      if (this.charity) return this.charity.logo
      return ''
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    checkoutBasket() {
      return this.$store.getters.checkoutBasket
    },
    charity() {
      return this.checkoutBasket.coupon.charity
    },
    isValidated() {
      if (this.personalDetails.will_for) return true
      return false
    },
    partner() {
      return this.$store.getters.partner
    },
    singlePrice() {
      return '£' + this.checkoutBasket.single_price / 100
    },
    jointPrice() {
      return '£' + this.checkoutBasket.joint_price / 100
    }
  },
  beforeRouteLeave(to, from, next) {
    this.save()
    next()
  },
  watch: {
    partner: {
      immediate: true,
      deep: true,
      handler(value) {
        this.phone = value.phone
        this.email = value.email
      }
    }
  },
  data() {
    return {
      phone: null,
      email: null
    }
  },
  methods: {
    save(value) {
      if (this.personalDetails) {
        this.$store.commit('personalDetails', {
          charity_donate: value
        })
      }
    }
  }
}
</script>
